import { useMemo, useCallback, ReactNode } from 'react';
import classnames from 'classnames';

import HoverTip, { TipPlacement } from 'bloko/blocks/drop/Tip/HoverTip';
import Text, { TextImportance } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent, format } from 'bloko/common/trl';

import EmployerReadStatisticsModule from 'HHC/EmployerReadStatisticsModule';
import ConversionDate from 'src/components/Conversion/DateOnly';
import translation from 'src/components/translation';
import useIsClient from 'src/hooks/useIsClient';
import { ApplicantEmployerPoliteness } from 'src/models/applicantEmployerPoliteness';
import {
    ManagerActivityData,
    ManagerInactiveTrlCode,
} from 'src/models/applicantNegotiations/applicantEmployerManagersActivity';

import styles from './responses.less';

enum ResponseSpeedColor {
    EmptyColor = '#E7E7E7',
    LowColor = '#DDA7A7',
    MediumColor = '#DDC7A7',
    HighColor = '#A7DDB0',
}

enum EmployerResponseSpeed {
    Low = 'low',
    Medium = 'medium',
    High = 'high',
}

const COLOR_SET = {
    [EmployerResponseSpeed.Low]: [
        ResponseSpeedColor.LowColor,
        ResponseSpeedColor.EmptyColor,
        ResponseSpeedColor.EmptyColor,
    ],
    [EmployerResponseSpeed.Medium]: [
        ResponseSpeedColor.MediumColor,
        ResponseSpeedColor.MediumColor,
        ResponseSpeedColor.EmptyColor,
    ],
    [EmployerResponseSpeed.High]: [
        ResponseSpeedColor.HighColor,
        ResponseSpeedColor.HighColor,
        ResponseSpeedColor.HighColor,
    ],
};

interface EmployerStatsProps {
    /** Объект работодательской статистики */
    employerPoliteness: ApplicantEmployerPoliteness;
    /** Объект активности менеджера */
    managerInactiveData?: ManagerActivityData;
}

const TrlKeys = {
    responseSpeed: {
        [EmployerResponseSpeed.Low]: 'applicant.negotiations.stats.responseSpeed.low',
        [EmployerResponseSpeed.Medium]: 'applicant.negotiations.stats.responseSpeed.medium',
        [EmployerResponseSpeed.High]: 'applicant.negotiations.stats.responseSpeed.high',
    },
    statsEmpty: 'applicant.negotiations.stats.noReadData',
    statsRead: 'applicant.negotiations.stats.read',
    statsWeekExact: 'applicant.negotiations.activity.weekExact',
    stats: {
        [ManagerInactiveTrlCode.Online]: 'applicant.negotiations.activity.online',
        [ManagerInactiveTrlCode.Today]: 'applicant.negotiations.activity.today',
        [ManagerInactiveTrlCode.Yesterday]: 'applicant.negotiations.activity.yesterday',
        [ManagerInactiveTrlCode.Week]: 'applicant.negotiations.activity.week',
        [ManagerInactiveTrlCode.Month]: 'applicant.negotiations.activity.month',
    },
};

const EmployerStats: TranslatedComponent<EmployerStatsProps> = ({ employerPoliteness, managerInactiveData, trls }) => {
    const isClient = useIsClient();
    const employerResponseSpeed = useMemo(() => {
        const replyTime = employerPoliteness?.replyTotalWorkingTimeDays;

        if (!replyTime || replyTime < 1) {
            return null;
        }

        if (replyTime === 1) {
            return EmployerResponseSpeed.High;
        }

        if (replyTime > 3) {
            return EmployerResponseSpeed.Low;
        }

        return EmployerResponseSpeed.Medium;
    }, [employerPoliteness]);

    const renderResponsesSpeedStat = useCallback(() => {
        if (!employerResponseSpeed) {
            return null;
        }

        return (
            <span className={styles.responsesTableEmployerCourtesyIndicator}>
                <svg width="13" height="14" fill="none">
                    <rect width="3" height="14" rx="1" fill={COLOR_SET[employerResponseSpeed][0]} />
                    <rect x="5" width="3" height="14" rx="1" fill={COLOR_SET[employerResponseSpeed][1]} />
                    <rect x="10" width="3" height="14" rx="1" fill={COLOR_SET[employerResponseSpeed][2]} />
                </svg>
            </span>
        );
    }, [employerResponseSpeed]);

    const renderStatsPercents = useCallback(
        (emptyStats: boolean) => {
            let statsPercents = '';

            if (emptyStats) {
                statsPercents = trls[TrlKeys.statsEmpty];
            } else {
                statsPercents = format(trls[TrlKeys.statsRead], {
                    '{0}': employerPoliteness?.readTopicPercent,
                });
            }

            return statsPercents;
        },
        [employerPoliteness, trls]
    );

    const renderStatsActivity = useCallback(() => {
        if (!managerInactiveData) {
            return '';
        }
        const managerInactiveTrlCode = managerInactiveData.trl_code;

        let statsActivity: ReactNode = '';

        if (managerInactiveTrlCode === ManagerInactiveTrlCode.WeekExact) {
            statsActivity = formatToReactComponent(trls[TrlKeys.statsWeekExact], {
                '{0}': <ConversionDate value={managerInactiveData.inactive_days} />,
            });
        } else if (managerInactiveTrlCode) {
            statsActivity = trls[TrlKeys.stats[managerInactiveTrlCode]];
        }

        return statsActivity && <p>{statsActivity}</p>;
    }, [managerInactiveData, trls]);

    const renderTooltip = useCallback(() => {
        const emptyStats = !employerPoliteness?.allTopicCount;
        const path = EmployerReadStatisticsModule.getPath(employerPoliteness?.readTopicPercent || 0);

        if (!isClient) {
            return null;
        }

        return (
            <HoverTip
                host={document.body}
                placement={TipPlacement.Top}
                render={() => (
                    <div data-qa="employer-stats-tooltip">
                        {renderStatsPercents(emptyStats)}
                        {employerResponseSpeed && <p>{trls[TrlKeys.responseSpeed[employerResponseSpeed]]}</p>}
                        {renderStatsActivity()}
                    </div>
                )}
            >
                <div
                    className={classnames(styles.responsesTableEmployerStats, {
                        [styles.responsesTableEmployerStatsEmpty]: emptyStats,
                    })}
                >
                    <Text importance={TextImportance.Tertiary}>
                        <div className={styles.responsesTableEmployerStatsIndicator}>
                            <svg viewBox="-1 -1 2 2" className={styles.responsesTableEmployerStatsSvg}>
                                <path className={styles.responsesTableEmployerStatsPath} d={path} />
                            </svg>
                        </div>
                        <span
                            className={styles.responsesTableEmployerStatsValue}
                            data-qa="negotiations-employer-statistics"
                        >
                            <span className={styles.responsesTableEmployerStatsNumber}>
                                {employerPoliteness?.readTopicPercent}
                            </span>
                            %
                        </span>
                        {renderResponsesSpeedStat()}
                    </Text>
                </div>
            </HoverTip>
        );
    }, [
        employerPoliteness,
        isClient,
        renderResponsesSpeedStat,
        renderStatsPercents,
        employerResponseSpeed,
        trls,
        renderStatsActivity,
    ]);

    return (
        <>
            <div className={styles.responsesTableEmployerStatsContainerDummy} />
            <div className={styles.responsesTableEmployerStatsContainer}>{renderTooltip()}</div>
        </>
    );
};

export default translation(EmployerStats);
