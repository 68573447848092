import type { Topic } from 'src/models/applicantNegotiationTopic.types';

export enum TopicStatus {
    Accepted = 'ACCEPTED',
    Rejected = 'REJECTED',
    Viewed = 'VIEWED',
    NotViewed = 'NOT_VIEWED',
    Archived = 'ARCHIVED',
    Deleted = 'DELETED',
}

export const getTopicStatus = (
    state: Topic['lastState'],
    subState: Topic['lastSubState'],
    isViewed: Topic['viewedByOpponent'],
    isArchived: Topic['archived']
): TopicStatus => {
    let status: TopicStatus = TopicStatus.NotViewed;

    if (subState === 'HIDE') {
        status = TopicStatus.Deleted;
        return status;
    }

    if (isArchived) {
        status = TopicStatus.Archived;
        return status;
    }

    if (state === 'RESPONSE') {
        status = isViewed ? TopicStatus.Viewed : TopicStatus.NotViewed;
    }

    if (state === 'INVITATION') {
        status = TopicStatus.Accepted;
    }

    if (state === 'DISCARD' || state === 'DISCARD_AFTER_INTERVIEW') {
        status = TopicStatus.Rejected;
    }

    return status;
};
