import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { ApplicantSubState } from 'src/models/negotiationTopic.types';

interface ListTopicStatusProps {
    /** Флаг архивной вакансии */
    archived: boolean;
    /** Последнее состояние топика */
    lastSubState: string;
}

const TrlKeys = {
    topicDeleted: 'applicant.negotiations.status.deleted',
    vacancyArchived: 'applicant.negotiations.vacancyArchivedShort',
};

const ListTopicStatus: TranslatedComponent<ListTopicStatusProps> = ({ archived, lastSubState, trls }) => {
    let status;

    if (!archived && lastSubState !== ApplicantSubState.Hide) {
        return null;
    }

    if (archived) {
        status = trls[TrlKeys.vacancyArchived];
    } else if (lastSubState === ApplicantSubState.Hide) {
        status = trls[TrlKeys.topicDeleted];
    }

    return <>{status}</>;
};

export default translation(ListTopicStatus);
