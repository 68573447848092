import { ChangeEvent, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { push } from 'connected-react-router';

import Select, { Option } from 'bloko/blocks/select';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import urlParser from 'bloko/common/urlParser';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import {
    ApplicantNegotiationStatus,
    FilterByStatus,
} from 'src/models/applicantNegotiations/applicantNegotiationsActionsData';

import styles from './responses.less';

const TrlKeys = {
    status: {
        [ApplicantNegotiationStatus.Discard]: 'applicant.negotiations.state.DISCARD',
        [ApplicantNegotiationStatus.Invitation]: 'applicant.negotiations.state.INVITATION',
        [ApplicantNegotiationStatus.Read]: 'applicant.negotiations.state.READ',
        [ApplicantNegotiationStatus.Unread]: 'applicant.negotiations.state.UNREAD',
        [ApplicantNegotiationStatus.OnlyNew]: 'applicant.negotiations.state.ONLY_NEW',
        [ApplicantNegotiationStatus.All]: 'applicant.negotiations.state.ALL',
    },
};

const FiltersByStatus: TranslatedComponent = ({ trls }) => {
    const filters = useSelector((state) => state.applicantNegotiationsActionsData.filterByStatus);
    const location = useSelector((state) => state.router.location);
    const dispatch = useDispatch();

    const [selected, setSelected] = useState<ApplicantNegotiationStatus>(ApplicantNegotiationStatus.All);
    const oldSelected = useRef<ApplicantNegotiationStatus>(ApplicantNegotiationStatus.All);
    const filtersMap = useMemo(() => {
        let newSelected = ApplicantNegotiationStatus.All;
        const filtersMap = filters.reduce((result, filter) => {
            result[filter.name] = filter;

            if (filter.selected) {
                newSelected = filter.name;
            }

            return result;
        }, {} as Record<ApplicantNegotiationStatus, FilterByStatus>);

        if (newSelected !== oldSelected.current) {
            oldSelected.current = newSelected;
            setSelected(newSelected);
        }

        return filtersMap;
    }, [filters]);

    const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const statusValue = event.target.value as ApplicantNegotiationStatus;
        setSelected(statusValue);
        if (statusValue === ApplicantNegotiationStatus.All) {
            const url = urlParser(location.pathname + location.search);
            url.params.state = [''];
            dispatch(push(url.href));
        } else {
            dispatch(push(filtersMap[statusValue].href));
        }
    };

    if (filters.length === 0) {
        return null;
    }

    return (
        <div className={classNames(styles.responsesTopControl, styles.responsesTopControlState)}>
            <Select onChange={handleChange} value={selected} flexible data-qa="responses-state-control">
                {filters.map((item) => (
                    <Option key={item.href} value={item.name}>
                        {trls[TrlKeys.status[item.name]]}
                    </Option>
                ))}
            </Select>
        </div>
    );
};

export default translation(FiltersByStatus);
