import type { FC } from 'react';
import { useMemo, useState } from 'react';

import { VSpacing, VSpacingContainer } from '@hh.ru/magritte-ui';

import { useSelector } from 'src/hooks/useSelector';
import type { Topic } from 'src/models/applicantNegotiationTopic.types';
import ApplicantNegotiationsListBanner from 'src/pages/ApplicantNegotiations/components/ApplicantNegotiations/ListBanner';
import mapListOfObjects from 'src/pages/ApplicantNegotiations/utils/mapListOfObjects';

import ApplicantNegotiationListBatchAction from 'src/pages/ApplicantNegotiations/components/ApplicantNegotiationsMagritte/List/BatchAction';
import ApplicantNegotiationCard from 'src/pages/ApplicantNegotiations/components/ApplicantNegotiationsMagritte/List/Card';
import ApplicantNegotiationListPagination from 'src/pages/ApplicantNegotiations/components/ApplicantNegotiationsMagritte/List/Pagination';
import { getTopicStatus } from 'src/pages/ApplicantNegotiations/components/ApplicantNegotiationsMagritte/List/utils';

const BANNER_PLACE_INDEX = 4;

const ApplicantNegotiationList: FC = () => {
    const [selectedTopics, setSelectedTopics] = useState<Topic['id'][]>([]);

    const { topicList: topics = [], paging } = useSelector((state) => state.applicantNegotiations);
    const vacancies = useSelector((state) => state.vacanciesShort.vacanciesList);
    const managerActivities = useSelector((state) => state.applicantEmployerManagersActivity);
    const politenessIndexes = useSelector((state) => state.applicantEmployerPoliteness.employerPolitenessIndexes);

    const VacancyMap = useMemo(() => mapListOfObjects(vacancies, 'vacancyId'), [vacancies]);
    const ManagerActivityMap = useMemo(() => mapListOfObjects(managerActivities, '@managerHhid'), [managerActivities]);

    const isAllSelected = selectedTopics.length === topics.length;
    const isSomeSelected = !!(selectedTopics.length && !isAllSelected);

    const handleSelect = (id: Topic['id']): void => {
        setSelectedTopics((prev) => (prev.includes(id) ? prev.filter((selected) => selected !== id) : [...prev, id]));
    };

    const handleSelectAll = (): void => {
        setSelectedTopics((prev) => (prev.length ? [] : topics.map(({ id }) => id)));
    };

    return (
        <VSpacingContainer default={24} xs={16}>
            {topics && topics.length > 0 && (
                <section style={{ boxSizing: 'border-box' }}>
                    <ApplicantNegotiationListBatchAction
                        isSomeSelected={isSomeSelected}
                        isAllSelected={isAllSelected}
                        onSelectAll={handleSelectAll}
                    />
                </section>
            )}
            <ul>
                {topics.map(
                    (
                        {
                            id,
                            vacancyId,
                            employerId,
                            lastModifiedMillis,
                            lastState,
                            lastSubState,
                            viewedByOpponent,
                            archived,
                            hasNewMessages,
                            employerViolatesRules,
                        },
                        index
                    ) => {
                        const vacancy = VacancyMap[vacancyId];
                        const card = (
                            <ApplicantNegotiationCard
                                id={id}
                                updatedAt={lastModifiedMillis}
                                status={getTopicStatus(lastState, lastSubState, viewedByOpponent, archived)}
                                isStatusChanged={hasNewMessages}
                                vacancyName={vacancy?.name}
                                employerName={vacancy?.company?.visibleName}
                                employerLogo={vacancy?.company?.logos?.logo[0]?.['@url']}
                                employerResponseRate={politenessIndexes[employerId]?.readTopicPercent}
                                employerLastActivity={ManagerActivityMap[vacancy?.employerManager['@hhid']]}
                                isEmployerBanned={employerViolatesRules}
                                isSelected={selectedTopics.includes(id)}
                                onSelect={handleSelect}
                            />
                        );

                        return (
                            <li key={id}>
                                {index !== 0 && <VSpacing default={24} xs={16} />}
                                {index === BANNER_PLACE_INDEX ? (
                                    <>
                                        <ApplicantNegotiationsListBanner key={`banner-${BANNER_PLACE_INDEX}`} />
                                        <VSpacing default={24} xs={16} />
                                        {card}
                                    </>
                                ) : (
                                    card
                                )}
                            </li>
                        );
                    }
                )}
            </ul>
            {paging && <ApplicantNegotiationListPagination paging={paging} />}
        </VSpacingContainer>
    );
};

export default ApplicantNegotiationList;
