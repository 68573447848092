import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import { Link } from '@hh.ru/redux-spa-middleware';
import { CrossScaleSmallEnclosedFalse, IconLink, IconColor } from 'bloko/blocks/icon';
import BlokoLink from 'bloko/blocks/link';

import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import fetcher from 'src/utils/fetcher';

const surveysAction = makeSetStoreField('surveys');

export const removeSurvey =
    ({ id }, addNotification) =>
    (dispatch, getState) => {
        const { surveys } = getState();

        return fetcher
            .postFormData('/surveys/finish', {
                sid: id,
            })
            .then(
                () => dispatch(surveysAction(surveys.filter((item) => item.id !== id))),
                (err) => defaultRequestErrorHandler(err, addNotification)
            );
    };

const Surveys = ({ surveys, removeSurvey, trls }) => {
    const isZarplata = useIsZarplataPlatform();
    const { addNotification } = useNotification();
    if (isZarplata) {
        return null;
    }
    if (surveys.length === 0) {
        return null;
    }

    return surveys.map(({ url, id, text }) => (
        <div className="announcement" key={id}>
            <BlokoLink disableVisited Element={Link} to={url} target="_blank">
                {trls[Surveys.trls.take]}
                {` "${text}"`}
            </BlokoLink>
            <IconLink onClick={removeSurvey.bind(null, { id }, addNotification)}>
                <CrossScaleSmallEnclosedFalse initial={IconColor.Gray50} />
            </IconLink>
        </div>
    ));
};

Surveys.trls = {
    take: 'survey.take',
};

Surveys.propTypes = {
    /** Список опросов */
    surveys: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            text: PropTypes.string,
            url: PropTypes.string,
        })
    ),
    trls: PropTypes.object,
    /** Сигнал удаления опроса */
    removeSurvey: PropTypes.func.isRequired,
};

export default connect((state) => ({ surveys: state.surveys }), { removeSurvey })(translation(Surveys));
