import { useState } from 'react';
import classnames from 'classnames';

import {
    Card,
    Checkbox,
    HSpacing,
    Tag,
    VSpacingContainer,
    Action,
    useBreakpoint,
    Text,
    Avatar,
    BottomSheet,
} from '@hh.ru/magritte-ui';
import { CircleFilledSize16, EllipsisHorizontalOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import type { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import HumanDate, { ShowYear } from 'src/components/HumanDate';
import translation from 'src/components/translation';
import {
    type ManagerActivityData,
    ManagerInactiveTrlCode,
} from 'src/models/applicantNegotiations/applicantEmployerManagersActivity';
import { TopicStatus } from 'src/pages/ApplicantNegotiations/components/ApplicantNegotiationsMagritte/List/utils';

import EmployerLastActivityTag from 'src/pages/ApplicantNegotiations/components/ApplicantNegotiationsMagritte/List/Card/EmployerLastActivityTag';
import EmployerResponseRateTag from 'src/pages/ApplicantNegotiations/components/ApplicantNegotiationsMagritte/List/Card/EmployerResponseRateTag';
import { TagStyleMap } from 'src/pages/ApplicantNegotiations/components/ApplicantNegotiationsMagritte/List/Card/utils';

import styles from './styles.module.less';

const TrlKeys = {
    status: {
        [TopicStatus.Accepted]: 'applicant.negotiations.topicStatus.accepted',
        [TopicStatus.Rejected]: 'applicant.negotiations.topicStatus.rejected',
        [TopicStatus.Viewed]: 'applicant.negotiations.topicStatus.viewed',
        [TopicStatus.NotViewed]: 'applicant.negotiations.topicStatus.notViewed',
        [TopicStatus.Archived]: 'applicant.negotiations.topicStatus.archived',
        [TopicStatus.Deleted]: 'applicant.negotiations.topicStatus.deleted',
    },
    employerViolatesRules: 'applicant.negotiations.employerViolatesRules',
};

interface ApplicantNegotiationCardProps {
    id: number;
    updatedAt: number;
    status: TopicStatus;
    isStatusChanged?: boolean;
    vacancyName?: string;
    employerName?: string;
    employerLogo?: string;
    employerLastActivity?: ManagerActivityData;
    employerResponseRate?: number;
    isEmployerBanned?: boolean;
    isSelected?: boolean;
    onSelect?: (id: number) => void;
}

const ApplicantNegotiationCard: TranslatedComponent<ApplicantNegotiationCardProps> = ({
    trls,
    id,
    updatedAt,
    status,
    isStatusChanged,
    vacancyName,
    employerName,
    employerLogo = '',
    employerLastActivity,
    employerResponseRate,
    isEmployerBanned,
    isSelected,
    onSelect,
}) => {
    const { isMobile } = useBreakpoint();

    const [isBottomSheetVisible, setBottomSheetVisible] = useState(false);

    const handleCheckboxChange = (): void => {
        if (onSelect) {
            onSelect(id);
        }
    };

    return (
        <Card showBorder stretched borderRadius={24} padding={12}>
            <VSpacingContainer default={20} xs={16}>
                <header className={styles.header}>
                    <div className={styles.toolbar}>
                        <Checkbox checked={isSelected} onChange={handleCheckboxChange} />
                        <HSpacing default={12} />
                        <Tag
                            icon={isStatusChanged ? CircleFilledSize16 : void 0}
                            style={TagStyleMap[status]}
                            data-qa={classnames({
                                'negotiations-item-invite': status === TopicStatus.Accepted,
                                'negotiations-item-discard': status === TopicStatus.Rejected,
                                'negotiations-item-not-viewed': status === TopicStatus.NotViewed,
                                'negotiations-item-viewed': status === TopicStatus.Viewed,
                            })}
                        >
                            {trls[isEmployerBanned ? TrlKeys.employerViolatesRules : TrlKeys.status[status]]}
                        </Tag>
                    </div>
                    {isMobile && (
                        <Action
                            icon={EllipsisHorizontalOutlinedSize24}
                            mode="secondary"
                            onClick={() => setBottomSheetVisible(true)}
                        />
                    )}
                </header>
                <section
                    className={classnames(styles.content, isMobile ? styles.contentMobile : styles.contentDesktop)}
                >
                    <VSpacingContainer default={4}>
                        <Text typography="subtitle-1-semibold" maxLines={2}>
                            {vacancyName}
                        </Text>
                        <Text typography="label-3-regular" data-qa="negotiations-item-company">
                            {employerName}
                        </Text>
                        <Text typography="label-3-regular" style="secondary" data-qa="negotiations-item-date">
                            <HumanDate date={updatedAt} showYear={ShowYear.NotCurrent} textify />
                        </Text>
                    </VSpacingContainer>
                    <Avatar
                        mode="image"
                        image={employerLogo}
                        fallbackMode="placeholder"
                        placeholder="city"
                        shapeCircle
                        size={isMobile ? 40 : 48}
                        online={employerLastActivity?.trl_code === ManagerInactiveTrlCode.Online}
                        aria-label="Employer Logo"
                    />
                </section>
                <section className={styles.tags}>
                    {!!employerLastActivity && <EmployerLastActivityTag lastActivity={employerLastActivity} />}
                    {!!employerResponseRate && <EmployerResponseRateTag responseRate={employerResponseRate} />}
                </section>
            </VSpacingContainer>
            {isMobile && (
                <BottomSheet
                    height="half-screen"
                    visible={isBottomSheetVisible}
                    onClose={() => setBottomSheetVisible(false)}
                >
                    {/* Экшны карточки */}
                </BottomSheet>
            )}
        </Card>
    );
};

export default translation(ApplicantNegotiationCard);
